import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { IntegrationModel } from '../integration/integration.model';

export interface IntegrationAppModel {
  id?: string;
  title?: IntegrationAppTitle;
  price?: number;
  plan?: PlanType;
  tooltip?: string;
  url?: string;
  tooltipsToTranslate?: IntegrationAppTooltip[];
  category?: IntegrationAppCategory;
  enabled?: boolean;
  display_name?: string;
  description?: string;
  canEdit?: boolean;
  integration_plus?: boolean;
  logoSrc?: string;
  logoVersion?: number;
}

export interface IntegrationAppWithIntegrationsModel extends IntegrationAppModel {
  integrations: IntegrationModel[];
}

export enum IntegrationAppTitle {
  DATAFOX = 'Datafox',
  NMBRS = 'Nmbrs',
  LIGHTSPEED_RESTAURANT_V2 = 'LightspeedRestaurantV2',
  LIGHTSPEED_RETAIL = 'LightspeedRetail',
  LOKET_V2 = 'LoketV2',
  UNTILL = 'Untill',
  GASTROFIX = 'Gastrofix',
  MPLUS = 'Mplus',
  TOMTOM = 'Tomtom',
  NCR = 'NCR',
  EUUR = 'Euur',
  ORTEC = 'Ortec',
  VECTRON = 'Vectron',
  WINSTON = 'Winston',
  EXACT = 'Exact',
  DATEV = 'Datev',
  EASYLON = 'Easylon',
  CONNEXIE = 'Connexie',
  HIOPOS = 'Hiopos',
  API = 'Api',
  EMPLOYES = 'Employes',
  AFAS = 'Afas',
  LIGHTSPEED_K_SERIES = 'LightspeedKSeries',
  HR_EN_SALARIS_GEMAK = 'HrEnSalarisGemak',
  FORMITABLE = 'Formitable',
  GUESTPLAN = 'Guestplan',
  PIGGY = 'Piggy',
  PIGGY_V2 = 'PiggyV2',
  UNITOUCH = 'Unitouch',
  LOON_NL = 'LoonNl',

  SD_WORX = 'SdWorx',

  // External
  GROWZER = 'Growzer',
  WAGESTREAM = 'Wagestream',
  EASYSECURE = 'Easysecure',
  TONIT = 'Tonit',
  ONETEAM = 'Oneteam',
  PERSONIO = 'Personio',
  PILOGA = 'PiLoga',
  TWELVE = 'Twelve',
  BCS = 'Bcs',
  JACANDO = 'Jacando',
  HOORAY = 'Hooray',
  TOP_CHEFS = 'TopChefs',
  BUDDEE = 'Buddee',
  BORK = 'Bork',
  JIXBEE = 'Jixbee',
  NEXTUP = 'NextUp',
}

export enum IntegrationAppCategory {
  PAYROLL = 'Payroll',
  TIME_ATTENDANCE = 'Time attendance',
  POS = 'POS',
  CHECK = 'Check',
  API = 'Public API',
  HR = 'HR',
}

export enum IntegrationAppTooltip {
  REGISTERED_HOURS_MANUAL = 'The registered hours export is triggered manually',
  EMPLOYEE_INFORMATION_NIGHTLY = 'Employee information is updated on a nightly basis.',
  CLOCK_REGISTRATION_IMMEDIATELY = 'All clock registrations are immediately pushed.',
  TURNOVER_NIGHTLY = 'The turnover is imported nightly.',
  CLOCK_REGISTRATION_3H = 'All completed clock registrations are imported every three hours.',
  RESERVATIONS_HOURLY = 'Each hour we check for new reservations and add them to the agenda.',
  CLOCK_REGISTRATION_5M = 'All clock registrations are updated every 5 minutes.',
  VECTRON_COLLAB = 'This integrations was made in collaboration with Vectron. Vectron manages this integration for you.',
}

export const integrationAppOptions = {
  [IntegrationAppTitle.API]: {
    configModal: 'app-token',
  },
  [IntegrationAppTitle.DATAFOX]: {
    configModal: 'datafox-configure',
    requestModal: 'datafox-request',
  },
  [IntegrationAppTitle.NMBRS]: {
    configModal: 'nmbrs-configure',
  },
  [IntegrationAppTitle.LIGHTSPEED_RESTAURANT_V2]: {
    configModal: 'lightspeed-restaurant-v2-configure',
  },
  [IntegrationAppTitle.LIGHTSPEED_RETAIL]: {
    configModal: 'lightspeed-retail-configure',
  },
  [IntegrationAppTitle.LIGHTSPEED_K_SERIES]: {
    configModal: 'lightspeed-k-series-configure',
  },
  [IntegrationAppTitle.LOKET_V2]: {
    configModal: 'loketv2-configure',
  },
  [IntegrationAppTitle.UNTILL]: {
    configModal: 'untill-configure',
  },
  [IntegrationAppTitle.GASTROFIX]: {
    configModal: 'gastrofix-configure',
    requestModal: 'gastrofix-request',
  },
  [IntegrationAppTitle.MPLUS]: {
    configModal: 'mplus-configure',
    requestModal: 'mplus-request',
  },
  [IntegrationAppTitle.TOMTOM]: {
    configModal: 'tomtom-configure',
  },
  [IntegrationAppTitle.NCR]: {
    configModal: 'ncr-configure',
  },
  [IntegrationAppTitle.EUUR]: {
    configModal: 'euur-configure',
  },
  [IntegrationAppTitle.VECTRON]: {
    configModal: 'vectron-configure',
  },
  [IntegrationAppTitle.WINSTON]: {
    configModal: 'mrwinston-configure',
  },
  [IntegrationAppTitle.EXACT]: {
    configModal: 'exact-configure',
  },
  [IntegrationAppTitle.DATEV]: {
    configModal: 'datev-configure',
  },
  [IntegrationAppTitle.EASYLON]: {
    configModal: 'easylon-configure',
  },
  [IntegrationAppTitle.CONNEXIE]: {
    configModal: 'connexie-configure',
  },
  [IntegrationAppTitle.HIOPOS]: {
    configModal: 'hiopos-configure',
  },
  [IntegrationAppTitle.EMPLOYES]: {
    configModal: 'employes-configure',
  },
  [IntegrationAppTitle.AFAS]: {
    configModal: 'afas-configure',
  },
  [IntegrationAppTitle.HR_EN_SALARIS_GEMAK]: {
    configModal: 'hr-salaris-gemak-configure',
  },
  [IntegrationAppTitle.FORMITABLE]: {
    configModal: 'formitable-configure',
    requestModal: 'formitable-request',
  },
  [IntegrationAppTitle.PIGGY]: {
    configModal: 'piggy-configure',
  },
  [IntegrationAppTitle.PIGGY_V2]: {
    configModal: 'piggyv2-configure',
  },

  [IntegrationAppTitle.GROWZER]: { externalUrl: 'https://growzer.com/' },
  [IntegrationAppTitle.EASYSECURE]: { externalUrl: 'https://www.easysecure.com/contact/' },
  [IntegrationAppTitle.WAGESTREAM]: { externalUrl: 'https://wagestream.com/en/#intercom' },

  [IntegrationAppTitle.TONIT]: {
    configModal: 'tonit-configure',
  },
  [IntegrationAppTitle.ONETEAM]: {
    requestModal: 'oneteam-request',
    configModal: 'oneteam-configure',
  },

  [IntegrationAppTitle.GUESTPLAN]: {
    configModal: 'guestplan-configure',
  },

  [IntegrationAppTitle.LOON_NL]: {
    configModal: 'loon-configure',
  },

  [IntegrationAppTitle.SD_WORX]: {
    configModal: 'sd-worx-configure',
  },

  [IntegrationAppTitle.UNITOUCH]: {
    configModal: 'unitouch-configure',
  },

  [IntegrationAppTitle.PERSONIO]: {
    generic: true,
  },
  [IntegrationAppTitle.PILOGA]: {
    generic: true,
  },
  [IntegrationAppTitle.BCS]: {
    generic: true,
  },
  [IntegrationAppTitle.TWELVE]: {
    generic: true,
  },
  [IntegrationAppTitle.TOP_CHEFS]: {
    generic: true,
  },
  [IntegrationAppTitle.BUDDEE]: {
    generic: true,
  },
  [IntegrationAppTitle.JACANDO]: {
    requestModal: 'jacando-request',
    configModal: 'jacando-configure',
  },
  [IntegrationAppTitle.BORK]: {
    requestModal: 'bork-request',
  },
  [IntegrationAppTitle.HOORAY]: {
    configModal: 'hooray-configure',
  },
  [IntegrationAppTitle.JIXBEE]: {
    requestModal: 'jixbee-request',
  },
  [IntegrationAppTitle.NEXTUP]: {
    requestModal: 'nextup-request',
  },
};
